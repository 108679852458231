:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: #fefefe;
    --header-padding: 1.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #6E667B;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 22px;
    
        /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;

}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Roboto.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#6E667B;
}

.red {
    background-color:#c00106;
}

.page {
    min-height:80vh;
}

.flex.margin.layout {
    align-items: flex-start;
}

/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0;
}

header a {
    color:black;
}

.topmenu {
    margin-top:1.5em;
    padding:0.6em 0;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    font-weight:700;
    color:white;
}

.menu li > a:hover, .menu .active > a {
    background: transparent;
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.menu li li a {
    padding:var(--topmenu-li-a-padding);;
}
.menu li:hover ul {
    margin-left:0;
}

.menu li li a:hover, .menu li .active a {
    color:#C00106;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.submenu ul {
    list-style:none;
    padding:0;
}

.submenu li {
    display:block;
    border-bottom:#eee solid 1px;
}

.submenu li a {
    display:block;
    text-decoration:none;
    padding:0.5em 0;
    color:#222;
    font-weight:500;
    font-size: calc(1 * var(--scale-factor) - 0.4rem);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.submenu li a:hover, .submenu .active a {
    color:#C00106;
}

.submenu li a::before {
    content:"∎";
    font-size: calc(1 * var(--scale-factor) - 0.6rem);
    display:inline-block;
    color:#e4e4e4;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.submenu li a:hover::before, .submenu .active a::before {
    color:#C00106;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin:0 auto;
    position: relative;
}
.startsida .slick-track {
    height: 60vh;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slider-inner {
    text-align:center;
    max-width:880px;
}

.slider-content span {
    display:block;
}

.slider-content span {
    display:block;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    color:white;
    font-family:Roboto;
}

.slider-heading {
    font-size:4rem;
    font-weight:700;
}

.slider-content-txt {
    font-size: calc(1 * var(--scale-factor-s)rem);
    font-weight:normal;
    color:white;
}



.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#6E667B;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#6E667B;
    display:block;
}

.slick-dots li {
    border:#6E667B solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}


/* Startbox
---------------------------------------------------------*/

.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.startbox-wrapper h2 {
    margin-top:1em;
}

.startbox-wrapper a {
    color:#3768B4;
}
    

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    border-bottom:#eee solid 1px;
    padding-bottom:1.5em;
    margin-bottom:1.5em;
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* startpage
---------------------------------------------------------*/

.startpage {
    min-height:40vh;
    padding:0;
}

.startpage-content {
    transition: 0.9s ease;
    -o-transition: 0.9s ease;
    -webkit-transition: 0.9s ease;
    color:white;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}

.startpage-inner {
    max-width:700px;
    margin:0 auto;
}

.startpage-content h2 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
}

.startpage-content:hover {
    background:#6E667B;
}


.startpage-img img {
    display:block;
}

.startbox a {
    display:block;
    text-decoration:none;
}

.startbox a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:2em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img {
    min-height:108px;
}

.pop-img img {
    display:block;
}

.readmore {
    margin-top:0.5em;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    padding-top:0.5em;
    color:#222;
}

    

/* page
---------------------------------------------------------*/

.inner-content  ul {
    list-style-type: none;
    margin:0;
    padding:0;
}

.inner-content ul li {
    padding-left: 1.2em;
    padding-bottom:0.4em;
    
}

.inner-content ul li:before {
  content: "\f046"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}

.topsocial {
    font-size:1.1rem;
}

.topsocial a {
    text-decoration:none;
}

.topsocial .fa {
    font-size: calc(1 * var(--scale-factor)rem);
    padding-right:0.5em;
    color:#C00106;
}

.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
}

.slider-for .for-img {
    border:#eee solid 1px;
    padding:0.5em;
    margin-bottom:1.5em;
}

.inner-content {
    padding:2em;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #8a8296;
    clear: both;
}

.pdf {
    margin-top:1.5em;
}

.pdf .inner-content ul li:before {
    content:"";
    width:auto;
    margin:0;
}

.pdf .inner-content li {
    padding:0 !important;
}

.content {
    margin-top:1.5em;
}

.partner .startbox-wrapper {
    text-align:left;
}

.partners-content {
    margin-top:0.5em;
}

body.kontakt {
    background:#f5f5f5;
}

iframe {
    max-width: 100%;
    width: 100%;
    height: 30vh;
    border: none;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: 1em;
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

.fa-map-marker:before {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.slider-for .slick-dots {
    bottom:-30px;
}


/* Footer
---------------------------------------------------------*/

footer {
    color:#fff;
    border-top:#8a8296 solid 16px;
}

footer span {
    display:block;
}

footer a {
    color: #fff;
    text-decoration:none;
}

footer a:hover {
    color:#FFDC02;
}

.socialamedia a {
    font-size:3.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.8rem;
    color:white;
}

.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.footer-text {
    margin-top:1.5em;
    padding-top:1.5em;
    border-top:#eee solid 1px;
}

.footer-text h4 {
    margin-bottom:0;
}

.footer-text p {
    margin-top:0;
}

footer .partners ul {
    list-style:none;
}

footer .partners li {
    display:inline-block;
}



/* other
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit], button[type=submit], input[type=submit] {
    margin:0;
    background:#C00106;
    border:#C00106 solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover, button[type=submit]:hover, input[type=submit]:hover {
    background:#222;
    border:#222 solid 1px;
    color:white;
}


a {
    color:#8a8296;
    text-decoration:none;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#222;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-family: OpenSans;
    font-weight: 400;
    color:#444444;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing:-1px;
    color:#222222;
    margin-top:0;
    margin-bottom:0.3em;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
  font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 30px;
}

h3 {
      font-size: 1.2rem;
    
}
/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    .homepage header {
        background:transparent;
    }
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    }
}

@media (max-width: 800px) {
    .bildspel_titel .container {
        font-size:1.1em;
    }
    #menu-icon {
        position:absolute;
        top:16px;
        right:10px;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    } 
    .submenu {
    -webkit-box-ordinal-group:3;
        -ms-flex-order:2;
            order:2
    }
    footer a {
        padding: 1.1em !important;
    }
}

@media (max-width:var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        color:black;
        box-shadow: 0 4px 2px -2px gray;
    }
    .menu li a, .menu li li a {
        color:black;
    }
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    .contact-padding {
        display:block;
        padding: 0.4rem 0;
    }

}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}
